// @flow

import React, { Component } from 'react';
import { Heading, VerticalMenu } from '@riseart/common';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';
import { Overlay, OverlayHeader, OverlayBody, OverlayContainer } from '@riseart/layout';

import {
  verticalMenuOverlayCls,
  verticalMenuOverlayBtnCls,
} from 'shared_components/common/menu/overlay/Menu.less';

type Props = {
  title: string,
  items: Array<Object>,
};

type State = { isOpened: boolean };

/**
 * VerticalMenuOverlay
 *
 * @param {Props} props
 */
export class VerticalMenuOverlay extends Component<Props, State> {
  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { isOpened: false };
    this.bindMethods();
  }

  /**
   * handleOverlayToggle
   */
  handleOverlayToggle: Function;

  handleOverlayToggle() {
    this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handleOverlayToggle = this.handleOverlayToggle.bind(this);
  }

  /**
   * render
   */
  render() {
    const { title, items } = this.props;

    return (
      <WithOffsetHeight>
        {({ notificationsHeight }) => {
          return (
            <React.Fragment>
              <span className={verticalMenuOverlayBtnCls} onClick={this.handleOverlayToggle} />
              <Overlay
                isVisible={this.state.isOpened}
                sidebar
                sidebarAlign="right"
                type="semitransparent"
                className={verticalMenuOverlayCls}
                onClose={this.handleOverlayToggle}
                topOffset={notificationsHeight}
                closeOnOutsideClick
              >
                <OverlayContainer>
                  <OverlayHeader>
                    <Heading tag="h3" level="4">
                      {title}
                    </Heading>
                  </OverlayHeader>
                  <OverlayBody>
                    <VerticalMenu
                      disableFixedMenu
                      items={items}
                      onItemClick={this.handleOverlayToggle}
                    />
                  </OverlayBody>
                </OverlayContainer>
              </Overlay>
            </React.Fragment>
          );
        }}
      </WithOffsetHeight>
    );
  }
}
